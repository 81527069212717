/* Contenedor de la barra de progreso (fondo negro) */
.progress-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 3px; /* Ajusta la altura según sea necesario */
    background-color: rgb(24, 24, 24); /* Fondo negro */
    z-index: 9999;
}

/* Barra de progreso (relleno blanco) */
.progress-bar {
    height: 100%;
    background-color: white; /* Color de la barra rellena */
    transform-origin: 0%; /* Hace que el crecimiento comience desde la izquierda */
    
}
