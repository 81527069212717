.button-dropdown {
    &[data-hover="true"] {
        background-color: transparent;
    }
}

.dropdown-menu {
    ul {
        flex-direction: row;
        overflow: auto;

        flex-wrap: wrap;
        li[role="menuitem"] {
            & > span {
                & > a {
                    position: relative;
                    width: auto;
                    & > span {
                        &::after {
                            content: "";
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            width: 0%;
                            height: 2px;
                            background-color: white;
                            transition: width 0.3s;
                        }
                    }
                }
            }
            &[data-hover="true"] {
                & > span {
                    & > a {
                        position: relative;
                        width: auto;
                        & > span {
                            &::after {
                                width: 100%;
                            }
                        }
                    }
                }

                &:hover {
                    background-color: transparent;
                }
            }
        }
    }
}

.accordion-menu {
    > h2 {
        margin-top: 0 !important;
    }
    button {
        padding: 0;
    }

    &[data-orientation="vertical"] {
        padding: 0;
    }
}

.accordion-level-1 {
    margin-left: 20px;
    > h2 {
        margin-top: 0 !important;
    }
}

.accordion-level-2 {
    span {
        margin-left: 20px;
        margin-bottom: 10px;
    }
}


.c-navbar{
    transition: background-color 0.5s;
    will-change: background-color;
    header {
        padding-left: 0;
        padding-right: 1px;
        max-width: var(--main-container-width);

    }

    
}




