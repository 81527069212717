button.back-to-top {
    margin: 0 !important;
    padding: 0 !important;
    background: #fff;
    height: 0px;
    width: 0px;
    overflow: hidden;
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    color: transparent;
    clear: both;
    visibility: hidden;
    position: fixed;
    cursor: pointer;
    display: block;
    border: none;
    right: 25px;
    bottom: 20px;
    font-size: 0px;
    outline: 0 !important;
    z-index: 99;
    transform: scale(0);
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
button.back-to-top:hover,
button.back-to-top:active,
button.back-to-top:focus {
    outline: 0 !important;
}
button.back-to-top.show::after,
button.back-to-top.show::before {
    visibility: visible;
}
button.back-to-top.show {
    display: block;
    background: #fff;
    color: black;
    font-size: 15px;
    right: 25px;
    bottom: 20px;
    height: 30px;
    width: 30px;
    transform: scale(1);
    visibility: visible;
    box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.25);
}
button.back-to-top.show:active {
    box-shadow: 0px 4px 8px 2px rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: 0px 4px 8px 2px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 4px 8px 2px rgba(0, 0, 0, 0.25);
}
